<!-- 订单管理-续费订单 -->

<template>
  <div class="order-renew main-cnt">
    <div class="content">
      <common-table ref="roleTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
        :isShowTable="false" :extraParame="{req_type:'4'}" :apiName="Order.getChargingOrderLists" :filters="filters"
        :columns="tableColumns" @calculateTotal="calculateTotal">
        <template #amount>
          <div class="flex s-m-t">
            <span class="amount-title f-w-bold">合计</span>
            <span class="order-totals">{{ totalAmount.orderTotal }}</span>
          </div>
        </template>
      </common-table>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, watch, } from "vue";
  import { Order, } from "@/plugins/api.js";
  import { useStore } from "vuex";
  import dayjs from "dayjs";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  // 监听权限
  watch(() => menuTokens.value, (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
    {
      deep: true,
      immediate: true,
    }
  )
  /** 表格对象 */
  const roleTable = ref(null);
  // 合计
  const totalAmount = ref({
    orderTotal: 0,  // 订单合计
  })
  /** 筛选条件列表 */
  const filters = ref([
    {
      filterType: "date",
      name: "start_time",
      name2: "end_time",
      value: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      placeholder: "请选择日期范围",
      type: "daterange",
    },
    {
      name: "keywords",
      filterType: "search",
      value: "",
      placeholder: "请输入用户手机号查询",
    },
  ]);

  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "orl_no",
      label: "订单编号",
      color: "--text-color",
      showTooltip: true,
      minWidth: 120,
    },
    {
      prop: "orl_renewal_money",
      label: "订单金额",
      color: "--text-color",
      showTooltip: true,
    },
    {
      prop: "m_mobile",
      label: "绑定用户",
      showTooltip: true,
    },
    {
      prop: "pay_time",
      label: "支付时间",
      color: "--text-color",
      showTooltip: true,
    },
    {
      prop: "e_name",
      label: "续费充电桩",
      color: "--text-color",
      showTooltip: true,
    },
    {
      prop: "s_name",
      label: "归属电站",
      color: "--text-color",
      showTooltip: true,
    },
    {
      prop: "useRange",
      label: "有效期",
      color: "--text-color",
      showTooltip: true,
    },
  ]);
  /**
   * 
   * 计算合计
   * 
   * */
  const calculateTotal = (datas) => {
    totalAmount.value = {
      orderTotal: 0,
    }
    datas.forEach(item => {
      totalAmount.value.orderTotal += Number(item.orl_renewal_money);
    })
    totalAmount.value.orderTotal = totalAmount.value.orderTotal.toFixed(2);
  }
  onMounted(() => {
    roleTable.value.tableLoad();
  });
</script>

<style lang="scss" scoped>
  .order-renew {
    font-family: "Source Han Sans CN";

    .el-row {
      border: none;
    }

    .content {
      padding: 20px;
    }

    .add-dialog {
      .el-dialog__body {
        padding: 20px 20px 30px 30px;
      }
    }
  }

  .amount-title {
    font-size: 18px;
  }

  .order-totals {
    margin-left: 250px;
    color: #1AC994;
  }
</style>